<template>
  <div>
    <div class="right ">
      <div class="card">
        <div>
          <div class="flex">
            <span
              v-if="!showOtherInsurer&&productID!==18"
              class="company-logo">
              <img
                :src="getOptionData('insureOptions',insurer).logo"
                alt="company" />
            </span>
            <span v-else>Other Insurer</span>
            <span
              v-if="productID==10"
              class="label"> Motor Insurance-<br>{{getOptionName('carClassOptions',carTypeName)}}</span>
            <span
              v-else-if="productID!==10||productID!==18"
              class="label">{{getOptionName('productNameOptions',productID)}}</span>
          </div>
        </div>
        <!-- <span
                class="label"
              >{{ getOptionName('classOptions', formData.class_id) }} Insurance {{ getOptionName('coverTypeOptions', formData.trm_of_cvr) }} Plan</span> -->
        <template v-if="!isSendQuote">
          <div
            class="card-body"
            :class="{'has-remark':hasOrderNo}"
          >
            <el-form
              ref="form"
              :model="formData"
              :disabled="disabled"
              :rules="rules">
              <div class="">
                <div
                  v-if="productMotor"
                  class="form-item">
                  <span>Base Premium</span>
                  <el-form-item prop="base_premium">
                    <v-input
                      v-model.number="formData.base_premium"
                      prepend="HKD"
                      :is-upper="false"
                      @change="handlerComputed"/>
                  </el-form-item>
                </div>
                <div
                  v-if="productMotor"
                  class="form-item">
                  <span>Client Discount</span>
                  <el-form-item prop="client_discount">
                    <v-input
                      v-model.number="formData.client_discount"
                      append="%"
                      :is-upper="false"
                      @change="handlerComputed"/>
                  </el-form-item>
                </div>
                <div
                  v-if="productMotor"
                  class="form-item">
                  <span>Loading</span>
                  <el-form-item prop="loading">
                    <v-input
                      v-model.number="formData.loading"
                      append="%"
                      :is-upper="false"
                      @change="handlerComputed"/>
                  </el-form-item>
                </div>
                <div
                  v-if="productMotor"
                  class="form-item">
                  <span>NCD</span>
                  <el-form-item prop="ncd">
                    <v-select
                      v-model="formData.ncd"
                      :list="ncdList"
                      :filterable="false"
                      @change="handlerChangeNcd"
                    />
                  </el-form-item>
                </div>
                <div
                  v-if="productMotor"
                  class="form-item">
                  <span>Basic Premium</span>
                  <el-form-item>
                    <v-input
                      v-model.number="showData.basic_premium"
                      disabled
                      :is-upper="false"
                      prepend="HKD"/>
                  </el-form-item>
                </div>
                <div
                  v-if="productMotor"
                  class="form-item">
                  <span>Additional Premium</span>
                  <el-form-item prop="additional_premium">
                    <v-input
                      v-model.number="formData.additional_premium"
                      prepend="HKD"
                      :is-upper="false"
                      @change="handlerComputed"/>
                  </el-form-item>
                </div>
                <div
                  v-if="!productMotor&&!isConstruction"
                  class="form-item">
                  <span>Premium</span>
                  <el-form-item prop="basic_premium">
                    <v-input
                      v-model.number= "formData.base_premium"
                      prepend="HKD"
                      :is-upper="false"
                      :disabled="isBusiness||isHome"
                      @change="handlerComputed"/>
                  </el-form-item>
                </div>
                <div
                  v-if="!isConstruction"
                  class="form-item">
                  <span>IA Levy</span>
                  <el-form-item prop="ia_levy_vl">
                    <div class="flex-aligin flex">
                      <span>
                        <v-input
                          v-if="isBusiness"
                          v-model.number="showData.ia_levy_vl"
                          prepend="HKD"
                          :is-upper="false"
                          disabled
                        />
                        <v-input
                          v-else
                          v-model.number="formData.ia_levy_vl"
                          prepend="HKD"
                          :is-upper="false"
                          :disabled="formData.is_ia_levy_vl==0"
                          @change="handlerComputed"
                        />
                      </span>
                      <v-checkbox
                        v-if="!showEcPercent||!showEcHKD"
                        v-model="formData.is_ia_levy_vl"
                        @change="handlerLevy"></v-checkbox>
                    </div>
                  </el-form-item>
                </div>
                <div v-if="isConstruction">
                  <div
                    class="form-item">
                    <span>Section 1 Premium</span>
                    <el-form-item prop="premium_material_damage">
                      <v-input
                        v-model.number= "formData.premium_material_damage"
                        prepend="HKD"
                        disabled
                        :is-upper="false"
                        @change="handlerComputed"/>
                    </el-form-item>
                  </div>
                  <div class="form-item">
                    <span>Section 1 IA Levy</span>
                    <el-form-item prop="section_1_ia_levy_vl">
                      <div class="flex-aligin flex">
                        <span>
                          <v-input
                            v-model.number="formData.section_1_ia_levy_vl"
                            prepend="HKD"
                            :is-upper="false"
                            :disabled="formData.is_section_1_ia_levy_vl==0"
                            @change="handlerComputed"/>
                        </span>
                        <v-checkbox
                          v-model="formData.is_section_1_ia_levy_vl"
                          @change="hanlderSection"
                        ></v-checkbox>
                      </div>
                    </el-form-item>
                  </div>
                  <div
                    class="form-item">
                    <span>Section 2 Premium</span>
                    <el-form-item prop="premium_third_party">
                      <v-input
                        v-model.number= "formData.premium_third_party"
                        prepend="HKD"
                        disabled
                        :is-upper="false"
                        @change="handlerComputed"/>
                    </el-form-item>
                  </div>
                  <div class="form-item">
                    <span>Section 2 IA Levy</span>
                    <el-form-item prop="section_2_ia_levy_vl">
                      <div class="flex-aligin flex">
                        <span>
                          <v-input
                            v-model.number="formData.section_2_ia_levy_vl"
                            prepend="HKD"
                            :is-upper="false"
                            :disabled="formData.is_section_2_ia_levy_vl==0"
                            @change="handlerComputed"/>
                        </span>
                        <v-checkbox
                          v-model="formData.is_section_2_ia_levy_vl"
                          @change="hanlderSection"
                        ></v-checkbox>
                      </div>
                    </el-form-item>
                  </div>
                </div>
                <div
                  v-if="showEcPercent"
                  class="form-item">
                  <span>EC Levy %</span>
                  <el-form-item >
                    <div class="flex-aligin flex">
                      <v-input
                        v-model.number= "formData.ec_levy	"
                        append="%"
                        :is-upper="false"
                        @change="handlerComputed"
                      />
                    </div>
                  </el-form-item>
                </div>
                <div
                  v-if="showEcHKD"
                  class="form-item">
                  <span>EC Levy</span>
                  <el-form-item >
                    <div class="flex-aligin flex">
                      <span>
                        <v-input
                          v-if="isBusiness"
                          v-model.number= "showData.ec_levy_vl"
                          :is-upper="false"
                          :disabled="isBusiness"
                          prepend="HKD"/>
                        <v-input
                          v-else
                          v-model.number= "formData.ec_levy_vl"
                          :is-upper="false"
                          :disabled="formData.is_ec_levy_vl==0"
                          prepend="HKD"
                          @change="handlerComputed"/>
                      </span>
                      <v-checkbox
                        v-if="showEcHKD"
                        v-model="formData.is_ec_levy_vl"
                        @change="handlerComputed"></v-checkbox>
                    </div>
                  </el-form-item>
                </div>
                <div
                  v-if="productMotor"
                  class="form-item">
                  <span>MIB</span>

                  <el-form-item >
                    <div class="flex-aligin flex">
                      <span>
                        <v-input
                          v-model.number= "formData.mib_vl	"
                          type="number"
                          prepend="HKD"
                          :is-upper="false"
                          :disabled="formData.is_mib_vl==0"
                          @change="handlerComputed"/>
                      </span>
                      <v-checkbox

                        v-model="formData.is_mib_vl"
                        @change="handlerComputed"></v-checkbox>
                    </div>

                  </el-form-item>
                </div>
                <div class="form-item">
                  <span>Total Premium</span>
                  <el-form-item>
                    <v-input
                      v-model.number= "showData.total_premium"
                      prepend="HKD"
                      :is-upper="false"
                      disabled/>
                  </el-form-item>
                </div>
                <div class="hr-inlne"></div>
                <div class="form-item">
                  <span>Gross Premium</span>
                  <el-form-item>
                    <v-input
                      v-model.number= "showData.gross_premium"
                      prepend="HKD"
                      :is-upper="false"
                      disabled/>
                  </el-form-item>
                </div>
                <div class="form-item">
                  <span>
                    <el-checkbox
                      v-model="isAmount"
                      @change="handlerChangeBox('isAmount','isDiscount',2)"></el-checkbox>
                    <span>Amount Received</span>
                  </span>
                  <el-form-item prop="amount_received">
                    <v-input
                      v-model.number="formData.amount_received"
                      prepend="HKD"
                      :is-upper="false"
                      :disabled ="isDiscount"
                      @change="handlerComputed"/>
                  </el-form-item>
                </div>
                <div class="form-item">
                  <span>
                    <el-checkbox
                      v-model="isDiscount"
                      :is-upper="false"
                      @change="handlerChangeBox('isDiscount','isAmount',1)"></el-checkbox>
                    <span>Special Discount %</span>
                  </span>
                  <el-form-item prop="special_discount">
                    <v-input
                      v-model.number="formData.special_discount"
                      append="%"
                      :disabled ="isAmount"
                      :is-upper="false"
                      @change="handlerComputed"/>
                  </el-form-item>
                </div>
                <el-collapse-transition>
                  <section v-show="showMore">
                    <div class="form-item">
                      <span>
                        <el-checkbox
                          v-model="net"
                          @change="handlerChangeNet('net','net_vl',1)"></el-checkbox>
                        <span>Sub Net %</span></span>
                      <el-form-item prop="net">
                        <v-input
                          v-model.number="formData.net"
                          append="%"
                          :is-upper="false"
                          :disabled="net_vl"
                          @change="handleInsureNet"/>
                      </el-form-item>
                    </div>
                    <div class="form-item">
                      <span>
                        <el-checkbox
                          v-model="net_vl"
                          @change="handlerChangeNet('net_vl','net',2)"></el-checkbox>
                        <span>Sub Net</span></span>
                      <el-form-item prop="net">
                        <v-input
                          v-model.number="formData.net_vl"
                          append="HKD"
                          :disabled="net"
                          :is-upper="false"
                          @change="handleInsureNet"
                        />
                      </el-form-item>
                    </div>
                    <!--            <div class="form-item">-->
                    <!--              <span>Net Comm. $</span>-->
                    <!--              <el-form-item >-->
                    <!--                <v-input-->
                    <!--                  v-model.number= "showData.net_commission_vl"-->
                    <!--                  prepend="HKD"-->
                    <!--                  disabled/>-->
                    <!--              </el-form-item>-->
                    <!--            </div>-->
                    <div class="form-item">
                      <span>
                        <el-checkbox
                          v-if="isShowInsuer"
                          v-model="insurer_net"
                          @change="handlerChangeInsurer('insurer_net','insurer_net_commission_vl',1)"></el-checkbox>
                        <span>Insurer Net %</span></span>
                      <el-form-item prop="insurer_net">
                        <v-input
                          v-model="formData.insurer_net"
                          append="%"
                          :is-upper="false"
                          :disabled="insurer_net_commission_vl"
                          @change="handlerInsureNet"
                        ></v-input>
                      </el-form-item>
                    </div>
                    <div class="form-item">
                      <span>Insurer Net </span>
                      <el-form-item >
                        <v-input
                          v-model.number="showData.insurer_net_vl"
                          prepend="HKD"
                          :is-upper="false"
                          disabled/>
                      </el-form-item>
                    </div>
                    <!--            <div class="form-item">-->
                    <!--              <span>-->
                    <!--                <el-checkbox-->
                    <!--                  v-if="isShowInsuer"-->
                    <!--                  v-model="insurer_net_commission_vl"-->
                    <!--                  @change="handlerChangeInsurer('insurer_net_commission_vl','insurer_net',2)"></el-checkbox>-->
                    <!--                <span> Insurer Net Comm.$</span>-->
                    <!--              </span>-->
                    <!--              <el-form-item >-->
                    <!--                <v-input-->
                    <!--                  v-model.number="formData.insurer_net_commission_vl"-->
                    <!--                  prepend="HKD"-->
                    <!--                  :disabled="!isShowInsuer||insurer_net"-->
                    <!--                  @change="handlerComputed"-->
                    <!--                />-->
                    <!--              </el-form-item>-->
                    <!--            </div>-->
                    <div class="hr-inlne"></div>
                    <div class="form-item">
                      <span>Sub Comm.</span>
                      <el-form-item >
                        <v-input
                          v-model.number="showData.referrer_commission_vl"
                          :is-upper="false"
                          prepend="HKD"
                          disabled/>
                      </el-form-item>
                    </div>
                    <div class="form-item">
                      <span>RM Comm.</span>
                      <el-form-item >
                        <v-input
                          v-model.number="showData.owner_commission_vl"
                          :is-upper="false"
                          prepend="HKD"
                          disabled/>
                      </el-form-item>
                    </div>
                  </section>
                </el-collapse-transition>
                <span
                  class="show-more flex-center"
                  @click="handleShowMore">
                  <font-awesome-icon :icon="showMore?'caret-up':'caret-down'"></font-awesome-icon>
                  <span class="mg-l-8">Show {{showMore?'Less':'More'}}</span>
                </span>
              </div>
            </el-form>
          </div>
        </template>
        <div class="card-button">
          <!--        hidden-sm-and-down-->
          <div class="flex-sb pd-20">
            <span class="item-name">Total Payable</span>
            <span class="item-val">HKD {{formData.amount_received|formatMoney(2)}}</span>
          </div>
          <div v-if="!isSave">
            <v-button
              v-if="!isNext"
              class="button-blue button-theme-padding-13"
              :disabled="isBlackList||base_premium_disable"
              @click="handlerNext">{{isPayment&&!isCreateRenew?'Update':'Next'}}</v-button>
            <v-button
              v-else
              class="button-blue  button-theme-padding-13"
              :disabled="isBlackList"
              @click="handlerPayment">
              <span class="flex-center ">
                <img
                  src="../../../assets/images/wallet.svg"
                  alt="">
                <span>Set Payment</span>
              </span>
            </v-button>
          </div>

        </div>

      </div>
    </div>
    <div>
      <div
        v-if="isSendQuote"
        class="contact-body">
        <contact-info :form="quoteInformationForm"></contact-info>
      </div>
      <div
        v-if="hasOrderNo"
        id="remark-height"
        ref="remark-height"
        class="remark-body">
        <internet-mark></internet-mark>
      </div>
    </div>
  </div>

</template>

<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import {mapActions, mapState} from 'vuex'
import {computedQuotation} from '@api/order'
import MixinFormat from '@/mixins/format'
import InternetMark from '@/views/components/internetMark'
import ContactInfo from '@/views/components/contact-info'

export default {
  name: 'discountDetail',
  components:{
    InternetMark,
    ContactInfo,
  },
  mixins: [MixinOptions,MixinRules,MixinFormat],
  props:{
    isNext:{
      type: Boolean,
      default:false
    },
    insurer:{
      type:Number,
      default: 1
    },
    carTypeName:{
      type:Number,
      default:1001
    },
    type:{
      type:Number,
      default: 1
    },
    form:{
      type:Object,
      default:()=>({})
    },
    disabled:{
      type:Boolean,
      default:false
    },
    isSave:{
      type:Boolean,
      default:false
    },
    isSendQuote:{
      type:Boolean,
      default:false
    },
    isPayment:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return{
      height:280,
      is_self:true,
      is_special: 1,
      is_net: 1,
      is_insurer_net:1,
      insurer_net:true,
      net:true,
      net_vl:false,
      insurer_net_commission_vl:false,
      is_section_2_ia_levy_vl:0,
      is_section_1_ia_levy_vl:0,
      showData:{
        gross_premium:'',
        total_premium:'',
        net_vl:'',
        owner_commission_vl:'',
        // insurer_net_commission_vl:'',
        insurer_net_vl:'',
        net_commission_vl:'',
        referrer_commission_vl:'',
        insurer_net: '',
        basic_premium:'',
        mib_vl:'',
        ia_levy_vl:'',
        ec_levy:'',
        ec_levy_vl:''
      },
      formData:{
        ncd:'',
        mib_vl:'',
        is_mib_vl:0,
        base_premium: '',
        loading: '',
        client_discount: '',
        additional_premium: '',
        ia_levy_vl: '',
        ec_levy_vl:'',
        ec_levy:'',
        amount_received: '',
        special_discount: '',
        net: '',
        net_vl: '',
        insurer_net:'',
        insurer_net_commission_vl:'',
        insurer_net_vl:'',
        is_net :1,//1 net 2:net_vl
        is_special: 1,//special_discount/amount_received只能使用其中一個。1-special_discount 2 - amount_received,
        premium_material_damage:'',
        premium_third_party:'',
        section_1_ia_levy_vl:'',
        section_2_ia_levy_vl:'',
        is_section_1_ia_levy_vl:0,
        is_section_2_ia_levy_vl:0,
        is_insurer_net:1,
        is_ia_levy_vl:0,
        is_ec_levy_vl:0,
        domestic_helpers_premium:'',
        c_e_c_premium:'',
      },
      isDiscount:true,
      isAmount:false,
      isIA:true,
      isMC:false,
      showMore:false
    }
  },
  computed: {
    ...mapState('quotation', ['ncd','ncdList','productID','commissionRate','insureNet']),
    ...mapState('coverage', ['totalPremium','riskPremium','domesticPremium','homePremium','constructionPremium']),
    ...mapState('quotation', ['quoteInformationForm']),
    isCreateRenew(){
      let {isRenew} = this.$route.query
      return isRenew == 'true'
    },
    hasOrderNo(){
      let {order_no,isDraft} = this.$route.query||{}
      return !!order_no
    },
    isBlackList(){
      let {blacklist=false} = this.clientBlackData||{}
      return blacklist
    },
    showOtherInsurer(){
      let arr =  [1,2,3,4,6,7,8,9,10,11,12,13]
      return !arr.includes(this.insurer)
    },
    productMotor(){
      return this.productID==10
    },
    isConstruction(){
      return this.productID==11
    },
    isBusiness(){
      return this.productID==15
    },
    isHome(){
      return this.productID==13
    },
    isCheckEC(){
      return [13,14,15,16,17,18].includes(this.productID)
    },
    showEcPercent(){
      // return this.productID==13
      return false
    },
    showEcHKD(){
      return [13,14,15,16,17,18].includes(this.productID)
    },
    isShowInsuer(){
      return [12,13,14,15,16,17,18,10].includes(this.productID)
    },
    base_premium_disable(){
      let {base_premium} = this.formData
      return base_premium==0||!base_premium
    }
  },
  watch:{
    form: {
      deep: true,
      immediate: true,
      handler(val) {
        Object.keys(this.formData).some(key => {
          if (key in val) {
            this.formData[key] = val[key]
            let {is_special,is_net,is_insurer_net,section_1_ia_levy_vl,section_2_ia_levy_vl,ia_levy_vl,ec_levy_vl} = val
            if( !is_special)
            {
              this.formData.is_special =1
              this.is_special =1
              this.isAmount = false
              this.isDiscount=true
            }
            this.isAmount = is_special==2
            this.isDiscount = is_special==1
            this.is_special = is_special
            if( !is_insurer_net)
            {
              this.formData.is_insurer_net =1
              this.is_insurer_net =1
              this.insurer_net_commission_vl = false
              this.insurer_net=true
            }
            this.net_vl = is_net==2?true:false
            this.net=is_net==1?true:false
            this.is_net=is_net
            if(!is_net){
              this.formData.is_net =1
              this.is_net =1
              this.net_vl = false
              this.net=true
            }
            // this.is_section_1_ia_levy_vl = section_1_ia_levy_vl>0?1:0
            // this.is_section_2_ia_levy_vl = section_2_ia_levy_vl>0?1:0
            this.formData.is_ia_levy = ia_levy_vl>0?1:0
            this.formData.is_ec_levy = ec_levy_vl>0?1:0
          }
        })
        this.is_self =false
        Object.keys(this.showData).some(key => {
          if (key in val) {
            this.showData[key] = val[key]
          }
        })
        if(this.productID==13){
          let {coverage} = this.quoteInformationForm||{}
          let {optional_benefit}=coverage||{}
          let {domestic_helpers_premium} = optional_benefit||{}
          this.formData.domestic_helpers_premium = domestic_helpers_premium||0
        }
        this.setNcd(val.ncd)

      }
    },
    ncd:{
      deep: true,
      immediate: true,
      handler(val,old) {
        this.formData.ncd = val
        if(val>=0&&!this.is_self)
        {
          this.is_self = false
          this.handlerComputed()
        }
      }
    },
    totalPremium:{
      immediate: true,
      handler(val,old) {
        if(val)
        {
          this.formData.base_premium = val
          this.handlerComputed()
        }
      }
    },
    riskPremium:{
      immediate: true,
      handler(val,old) {
        if(val)
        {
          this.formData.premium_material_damage = val.premium_material_damage
          this.formData.premium_third_party = val.premium_third_party
          this.handlerComputed()
        }
      }
    },
    insureNet:{
      immediate: true,
      handler(val,old) {
        if(val)
        {
          this.formData.insurer_net = val
          this.handlerComputed()
        }
      }
    },
    domesticPremium:{
      immediate: true,
      handler(val,old) {
        if(val&&this.productID==13)
        {
          let {domestic_helpers_premium=0}=val||{}
          this.formData.domestic_helpers_premium = domestic_helpers_premium||0
          // this.formData.premium_third_party = val.premium_third_party
          this.handlerComputed()
        }
      }
    },
    homePremium:{
      immediate: true,
      handler(val,old) {
        if(val&&this.productID==13)
        {
          let {home_contents_all_risk=0,personal_accident=0,personal_liability=0,domestic_helpers_premium=0,other_premium=0,building_premium=0} =val||{}
          let premium =Number( home_contents_all_risk)+Number(personal_accident)+Number(personal_liability)+Number(domestic_helpers_premium)+Number(other_premium)+Number(building_premium)
          this.formData.base_premium= premium
          this.handlerComputed()
        }
      }
    },
    constructionPremium:{
      immediate: true,
      handler(val,old) {
        if(val&&this.productID==17)
        {
          this.formData.c_e_c_premium= val||0
          this.handlerComputed()
        }
      }
    },
    commissionRate:{
      immediate:true,
      handler(val){
        if(val!=null){
          this.formData.net =val
          this.handlerComputed()
        }
      }
    }
  },
  mounted() {
    const element = this.$refs['remark-height']
    // this.height = element.clientHeight +20
  },
  methods:{
    ...mapActions('quotation', ['setNcd','setCommissionNet','setInsureNet']),
    ...mapActions('coverage', ['setDomesticPremium','setHomePremium']),
    handleShowMore(){
      this.showMore = !this.showMore
    },
    handlerCancel(){
      this.$router.back()
    },
    handlerChangeNcd(){
      this.is_self = false
      this.setNcd(this.formData.ncd)
      // this.network().computedQuotation()

    },
    submitForm(formName) {
      this.$refs['form'].validate(valid => {
        if(valid) {
          this.$emit('getFormData', formName, this.formData)

        } else {

        }
      })
    },
    handlerNext(){
      this.setDomesticPremium(null)
      this.setHomePremium(null)
      this.$emit('next',true)
    },
    handleBack(){
      this.setDomesticPremium(null)
      this.setHomePremium(null)
      this.$router.back()
    },
    handleCancel(){
      this.setDomesticPremium(null)
      this.setHomePremium(null)
      this.$ConfirmBox({
        title: '',
        message: 'Quote will not be auto saved,are you confirmed to leave?'
      }).then(() => {
        this.$router.push({
          path:'/quotation/createQuoteHome',
        })
      })
      this.$emit('cancel',true)
    },
    handlerDraft(){
      this.$emit('draft',true)
    },
    handlerPayment(){
      this.$emit('next',true)
    },
    handlerLevy(){
      this.formData.ia_levy_vl = this.formData.is_ia_levy==0?0:this.formData.ia_levy_vl
      this.handlerComputed()
    },
    handleInsureNet(){
      this.setCommissionNet(null)
      this.handlerComputed()
    },
    handlerComputed(){
      this.network().computedQuotation()
    },
    hanlderSection(){
      // if(this.is_section_1_ia_levy_vl==0)
      //   this.formData.section_1_ia_levy_vl = 0
      // if(this.is_section_2_ia_levy_vl==0)
      //   this.formData.section_2_ia_levy_vl = 0
      this.handlerComputed()
    },
    handlerChangeBox(name1,name2,num){
      this[name1] = true
      this[name2] =false
      this.formData.is_special = num
      this.is_special = num
      setTimeout(()=>{
        this.handlerComputed()
      },500)
    },
    handlerChangeInsurer(name1,name2,num){
      this[name1] = true
      this[name2] =false
      // this.formData.is_special = num
      this.formData[name2] = ''
      this.formData.is_insurer_net = num
      this.is_insurer_net = num
      setTimeout(()=>{
        this.handlerComputed()
      },500)
    },
    handlerChangeNet(name1,name2,num){
      this[name1] = true
      this[name2] =false
      // this.formData.is_special = num
      this.formData[name2] = ''
      this.formData.is_net = num
      this.is_net = num
      setTimeout(()=>{
        this.handlerComputed()
      },500)
    },
    handlerInsureNet(){
      this.setInsureNet(null)
      this.handlerComputed()
    },
    network() {
      return {
        computedQuotation:async ()=>{
          this.is_self= false
          let {is_edit,order_no} = this.quoteInformationForm||{}
          let params ={...this.formData,...{is_special:this.is_special,product_id:this.productID}}
          if(is_edit)
            params.order_no = order_no
          let { data } = await computedQuotation(params)
          // this.formData = data
          this.showData = data
          Object.keys(this.formData).some(key => {
            if (key in data) {
              this.formData[key] = data[key]
            }
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.right {
  //margin-left: 40px;
  //min-width: 300px;
  margin-top: 20px;
  margin-bottom: 20px;
  .card{
    background: white;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
    .card-body{
      background: #F7FAFC;
      padding: 10px;
      border-radius: 5px;
      &::-webkit-scrollbar {
        width: 6px; // 横向滚动条
        height:12px; // 纵向滚动条 必写
        display: block;
        background: #CBD5E0;
        cursor: pointer;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #718096;
        border-radius: 3px;
        cursor: pointer;
      }
      .form-item{
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        .el-form-item{
          margin-bottom: 0;
        }
        span{
          flex: 1;
          font-size: 14px;
          font-weight: 700;
        }
        .el-form-item{
          flex: 2;
        }
      }
      .hr-inlne{
        height: 0.5px;
        background: #CBD5E0;
        margin-bottom: 12px;
      }
    }
    .card-button{
      background: #fff;
      width: 100%;
      padding-top: 10px;
      .flex{
        margin-bottom: 12px;
        .el-button{
          width: 50%;
        }

      }
      .el-button{
        width: 100%;
        font-weight: bold;
      }
    }
  }
  .company-logo {
    // display: flex;
    // justify-content: center;
    // align-items: center;
     width: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;

    img {
      display: block;
       width: 100%;
      // max-height: 100%;
      //height: 42px;
    }
  }

  .label {
    display: block;
    margin: 10px 0;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
  }

  .total-box {
    margin: 15px -15px -15px -15px;
    padding: 15px;
    background: #ecf5ff;

    span {
      display: block;
      color: #616266;
      font-size: 14px;
    }

    .amount {
      font-size: 24px;
      color: #409eff;
      font-weight: bold;
    }
  }
  .flex{
    align-items: center;
    .label{
      margin-left: 10px;
    }
  }
  .flex-aligin{
    display: -webkit-flex;
    width: 100%;
    /*padding-right: 25px;*/
    .el-checkbox{
      /*flex: 1;*/
    }
    span{
      width: 100%;
    }
    .el-input{
      /*width: 294px!important;*/

    }
  }
  .flex-center{
    align-items: center;
    justify-content: center;
    img{
      margin-right: 5px;
    }
  }
  .edit-button{
    .button-theme{
      width: 100%;
      margin-bottom: 20px;
    }
    .button-blue{
      padding: 0;
      margin-left: 0;
    }
  }
  ::v-deep .el-checkbox{
    .is-checked{
      .el-checkbox__inner{
        background: $primary!important;
        border-color:$primary;
      }
    }
  }
}
.item-name{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  /* or 17px */

  display: flex;
  align-items: center;

  /* Gray/Gray-500 */

  color: #718096;
}
.pd-20{
  padding: 20px 0;
}
.item-val{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;

  display: flex;
  align-items: center;
  text-align: right;
  color: #000000;
}
.show-more{
  color: $primary;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 13px;
  cursor: pointer;
  span{
    display: inline-block;
    margin-left: 8px;
  }
}
.contact-body{
  margin-bottom: 20px;
}
</style>
